@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-image: url("./Background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
